












import Vue from "vue";

export default Vue.extend({
  metaInfo: {
    title: "404 - Not Found",
    meta: [{ name: "robots", content: "noindex" }],
  },
  methods: {
    navigateHome() {
      this.$router.replace("/");
    },
  },
});
